import React from 'react'
import '../assets/css/About.styl'
function About() {
        return (
            <div className="about flex-justify-center flex-column">
               <div className="banner flex-items-center flex-justify-center bold fc-black">
              
               </div>
               <div className="flex-column flex-items-center">
                    <div className="about-title fs-36 mt-36 bold">
                        <div className="tit">
                            关于我们
                        </div>
                    </div>
                    <div className="about-content fs-24 text-center fc-hui8">
                        <div className="mt-333">
                        武汉晴空飞翼网络科技有限公司成立于2022年，位于湖北省武汉市，致力于成为专业技术服务行业的领先企业。自成立以来，公司始终专注于创新和用户体验，凭借卓越的技术实力和敏锐的市场洞察力，不断推出满足大众需求的移动应用。
                        </div>
                        <div className="mt-333">
                        公司旗下的房贷智多星和疾速充电助手等应用，凭借其卓越的功能设计和用户友好的界面，迅速赢得了广大用户的青睐，累计移动端用户已经突破1.2亿。
                        </div>
                        <div className="mt-333">
                        秉承“科技让生活更美好”的理念，武汉晴空飞翼网络科技有限公司将持续创新，致力于为用户提供更加智能、便捷的生活服务。未来，公司将进一步拓展技术服务领域，开发更多创新型应用，以满足不断变化的市场需求，努力成为行业的引领者和用户心目中的首选品牌。
                        </div>
                        {/* <div className="mt-30">
                        联系电话：15210172760（周一至周日，10:00-18:00）
                        </div>
                        <div className="mt-30">
                        联系地址：湖北省武汉市东西湖区泾河街办事处吴金路171号10KW及以下电能质量产品生产项目1号厂房4层C48 
                        </div> */}
                        <div>
    
                        </div>
                    </div>
               </div>
            </div>
        );
    }
export default About;